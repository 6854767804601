import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { Menu, Label, Popup, Checkbox, Icon } from 'semantic-ui-react';
import omit from 'lodash/omit';
import gql from 'graphql-tag';
import * as utils from '../../utils';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import Avatar from '../../components/Avatar';
import ColoredCheckbox from '../../components/ColoredCheckbox';

import { __ } from '../../i18n';

const entityTypes = organizationType => ({
  STUDENT: { name: (utils.checkIfItIsClientOrSchool(organizationType) === 1) ? __('Student') : __('Client'), color: 'grey' },
  STAFF: { name: __('Staff'), color: 'brown' },
  ADMIN: { name: __('Admin'), color: 'black' }
});
@inject('store')
@observer
export default class EntityItem extends React.Component {

  static fragments = {
    entity: gql`
      fragment EntityItemEntity on Entity {
        id: dbId
        fullname
        shortname
        seeAll
        code
        disabled
        type
        isChannel
        picture {
          uri
          id: dbId
          key
        }
        organization {
          id: dbId
          type
        }
        unreadMessages: messages (folder: UNREAD) {
          totalCount
        }
        newMoments: moments (folder: NEW) {
          totalCount
        }
      }`,
    recipient: gql`
        fragment EntityItemRecipient on Entity {
          id: dbId
          fullname
          shortname
          seeAll
          code
          disabled
          type
          picture {
            uri
            id: dbId
            key
          }
          organization {
            id: dbId
            type
          }
        }`,
    user: gql`
      fragment EntityItemUser on User {
        id: dbId
        fullname
        tags {
          nodes {
            id: dbId
            name
          }
        }
      }`,
  }

  formatOpeningHours(startTime, endTime) {
    if (startTime && endTime) {
      startTime = moment.utc(startTime, 'HH:mm').format('LT').toLowerCase()
      endTime = moment.utc(endTime, 'HH:mm').format('LT').toLowerCase()
      return __('Office hours from %s to %s', startTime, endTime)

    }

  }

  render() {
    const { entity, onClick, indicator, to, wrapType, user, limit, position, selected, adminForm, fromMessage, coloredCheckbox, invertCheckbox, subTitle, nameStyle } = this.props;
    const newProps = omit(this.props,
      ['entity', 'onClick', 'indicator', 'wrapType', 'to', 'user', 'limit', 'position']);

    const { ntfStartTime, ntfEndTime, organization } = entity
    if (!entity) return null;

    const params = {};
    if (to) {
      params.as = Link;
      params.to = to;
    }

    const organizationType = organization && organization.type;
    const userTags = user && user.tags && user.tags.nodes && user.tags.nodes.map(item => (
      <div style={{ display: 'block' }}>
        <span key={item.name} id={item.id} style={{ fontSize: '12px', fontWeight: 'medium' }}>
          {item.name}
        </span>
      </div>));

    let responseTime = null
    if (entity && entity.dailyMessagesMetrics && entity.dailyMessagesMetrics.length > 0) {
      responseTime = utils.calculateReplyTime(entity.dailyMessagesMetrics[0]);
    }
    const stringTime = utils.handleDirectReplyTime(responseTime)
    const openingHours = wrapType ? this.formatOpeningHours(ntfStartTime, ntfEndTime) : null
    return (
      <Menu.Item
        className="entityItem"
        title={entity.fullname}
        data-id={entity.id}
        onClick={onClick ? () => onClick(entity) : null}
        {...params}
        {...newProps}
      >
        {
          indicator === 'sidebar' && (entity.unreadMessages.totalCount + entity.newMoments.totalCount) > 0 &&
          <Label circular color="brown" size="mini">{entity.unreadMessages.totalCount + entity.newMoments.totalCount}</Label>
        }
        {
          indicator === 'checkbox' && position === 'left' && !coloredCheckbox &&
          <Checkbox checked={entity.selected || selected} style={{ verticalAlign: 'middle', marginRight: '10px' }} />
        }
        {indicator === 'checkbox' && position === 'left' && coloredCheckbox &&
          <ColoredCheckbox data-action="select-all" style={{ marginRight: '15px' }} className="bold" radio radioChecked bold={false} color="#084FFF" checked={entity.selected || selected} />
        }
        <span style={indicator === 'sidebar' && entity.disabled ? { opacity: '0.3' } : wrapType ? { width: '102%', display: 'flex', flexDirection: 'row' } : {}}>
          {

            !fromMessage ?

              wrapType ?
                <div ref={(c) => { this.ref = c }} style={{ display: 'flex', flexDirection: 'row', flex: 1, }}>
                  <Avatar avatar src={entity.picture && entity.picture.uri} style={{ height: '40px', width: '40px' }} alt={entity.fullname} />

                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column', marginLeft: '8.5px', ...nameStyle }}>
                    <span style={{ margin: 'auto 4px', fontSize: '16px', fontWeight: (to && indicator !== 'sidebar') ? 'bold' : 'normal' }}>
                      {
                        limit ?
                          (
                            entity.fullname.length > 25 ?
                              <Popup
                                trigger={<span>{entity.fullname.replace(/^(.{25}).+/, '$1...')}</span>}
                                content={<span>{entity.fullname}</span>}
                              />
                              :
                              entity.fullname
                          )
                          :
                          entity.fullname || __('Deleted Person')
                      }
                    </span>

                    {subTitle ?
                      <span style={{ margin: '6px 4px', color: 'rgb(0, 0, 0)' }}>
                        {subTitle}
                      </span>
                      :
                      <span style={{ margin: '6px 4px', color: 'rgba(0, 0, 0, 0.4)' }}>
                        {entityTypes(organizationType)[entity.type].name}
                      </span>
                    }

                    {entity.description && this.props.store.currentEntity.type === 'STUDENT' ?
                      <span style={{ margin: 'auto 4px', color: 'rgba(0, 0, 0, 0.6)' }}>
                        <div className={'entity-description'}>
                          {entity.description}
                        </div>
                      </span> : null}

                    {this.props.store.currentEntity.type === 'STUDENT' &&
                      <span style={{ margin: 'auto 4px', marginTop: '6px', color: 'rgba(0, 0, 0, 0.4)' }}>
                        <div>{openingHours}</div>
                      </span>
                    }

                  </div>


                  {this.props.store.currentEntity.type === 'STUDENT' && organization.confChannelMetrics && responseTime ?
                    <div className="reply_time">
                      <Icon name="clock" />
                      <p>{stringTime}</p>
                    </div>
                    : null}

                </div>
                :
                <Fragment>
                  <Avatar avatar src={entity.picture && entity.picture.uri} alt={entity.fullname} />
                  <span style={{ margin: 'auto 4px', fontWeight: (to && indicator !== 'sidebar') ? 'bold' : 'normal' }}>
                    {
                      limit ?
                        (
                          entity.fullname.length > 25 ?
                            <Popup
                              trigger={<span>{entity.fullname.replace(/^(.{25}).+/, '$1...')}</span>}
                              content={<span>{entity.fullname}</span>}
                            />
                            :
                            entity.fullname
                        )
                        :
                        entity.fullname || __('Deleted Person')
                    }
                  </span>
                  {' '}
                  {['sidebar', 'typeLabel'].includes(indicator) &&
                  <Label color={entityTypes(organizationType)[entity.type].color} horizontal size="tiny" className="uppercase">
                    {entityTypes(organizationType)[entity.type].name}
                  </Label>}
                  {user && user.fullname && !this.props.noFullname && <Label basic size="tiny" className="uppercase" content={user.fullname} />}
                </Fragment>
              :
              <div>
                <Avatar avatar src={entity.picture && entity.picture.uri} alt={entity.fullname} style={{ width: '40px', height: '40px' }} />
                {' '}
              </div>
          }
          {
            indicator === 'checkbox' && !wrapType &&
            <Label color={entityTypes(organizationType)[entity.type].color} horizontal size="tiny" className="uppercase" style={{ float: 'right' }} >
              {entityTypes(organizationType)[entity.type].name}
            </Label>
          }
          {user && user.fullname && !this.props.fromRemind && (
            user.tags && user.tags.nodes && user.tags.nodes.length ?
              <Popup
                trigger={<Label basic size="tiny" className="uppercase" content={user.fullname} />}
                content={userTags}
              />
              :
              <Label basic size="tiny" className="uppercase" content={user.fullname} />
          )
          }
          {/* entity.description && <span>{entity.description}</span>*/}
          {
            indicator === 'checkbox' && position !== 'left' && !invertCheckbox &&
            <Checkbox checked={entity.selected || selected} style={{ verticalAlign: 'middle', float: 'right' }} />
          }
        </span>
        {
          indicator === 'checkbox' && position !== 'left' && coloredCheckbox && invertCheckbox &&
          <ColoredCheckbox data-action="select-all" style={{ marginRight: '10px' }} className="bold" radio radioChecked bold={false} color="#084FFF" checked={entity.selected || selected} />
        }
      </Menu.Item >
    );
  }
}
